import { breakPoints } from '../breakpoints';

export const h5 = {
  fontStyle: 'normal',
  fontWeight: 700,
  [breakPoints.down('xl')]: {
    fontSize: 18,
  },
  [breakPoints.up('xl')]: {
    fontSize: 22,
  },
};
