import colors from '../colors';

const MuiInput = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: colors.cyanBlue,
  },
  underline: {
    '&:hover:not($disabled):before': {
      borderBottom: `1px solid ${colors.lavender}`,
    },
  },
  input: {
    color: colors.catalinaBlue,
  },
};

export default MuiInput;
