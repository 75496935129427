import combineReducers from '../utils/combinerReducers';
import notificationReducer from './reducers/notification';

// Need to add reducers here
export const reducer = {
  notificationReducer,
};

// Reducers to be combiner here similar to redux
export default combineReducers(reducer);
