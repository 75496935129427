import colors from '../colors';

const MuiButton = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    padding: '12px 25px 12px 12px',
    borderRadius: 50,
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
  },
  label: {
    color: 'white',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '0.05rem',
  },
  containedPrimary: {
    backgroundColor: colors.marinerBlue,
    '&:hover': {
      backgroundColor: colors.marinerBlue,
    },
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 'unset',
    },
  },
  startIcon: {
    marginRight: 12,
    marginLeft: 'unset',
  },
};

export default MuiButton;
