const DEFAULT_TIMEOUT = 5000;

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

export const showNotification = (notification) => ({
  type: SHOW_NOTIFICATION,
  payload: notification,
});

export const dismissNotification = () => ({
  type: DISMISS_NOTIFICATION,
});

export const displayNotification = (notification) => {
  return (dispatch) => {
    dispatch(showNotification(notification));
    const notificationTimer = setTimeout(() => {
      const dismissCallback = notification?.options?.onDismissCallback();

      if (dismissCallback) {
        dismissCallback();
      }

      dispatch(dismissNotification());
    }, notification.options?.timeout || DEFAULT_TIMEOUT);

    return notificationTimer;
  };
};
