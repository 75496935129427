import React, { useEffect, useState } from 'react';
import { useTreatmentPlans } from '@dechea/hes.hooks.use-treatment-plans';
import { TabWithText } from '@dechea/hes.ui.compositions.tab-with-text';
import { getLoggedInUserData } from '@dechea/orc.functions.get-user-details-from-token';
import { Snackbar } from '@dechea/orc.services.library';

import { accessToken } from '../../index';
import { ConfirmationDialog } from './ConfirmationDialog';
import { history } from '../../router/history';

export const TreatmentTabsWithText = ({
  onCreateNewPlanTreatmentTab,
  patientId,
  patientUserId,
  planId,
}) => {
  const [haveTreatments, setHaveTreatments] = useState(false);
  const [treatmentTabIndexForDeletion, setTreatmentTabIndexForDeletion] =
    useState(null);
  const loggedInUserData = accessToken && getLoggedInUserData(accessToken);

  // call useTreatmentPlans hook to get all treatment plan tabs
  const {
    treatmentTabs,
    handleClickOnAddNewTabButton,
    addTreatmentsToPlan,
    activeTreatmentPlanTabIndex,
    setActiveTreatmentPlanTabIndex,
    notification,
    onDeleteTreatmentPlanTab,
    onRemoveTreatment,
    onAddTreatmentListItem,
  } = useTreatmentPlans({
    createdByUserId: loggedInUserData?.userId,
    patientUserId,
    accessToken,
    patientId,
  });

  const activePlanTab = treatmentTabs[activeTreatmentPlanTabIndex];
  const treatments = activePlanTab?.treatments || [];
  const activePlanIndex = treatmentTabs?.findIndex(({ id }) => id === planId);
  const activeTreatmentPlanId = treatmentTabs[activeTreatmentPlanTabIndex]?.id;

  const handleTreatmentTabChange = (_e, index) => {
    setActiveTreatmentPlanTabIndex(index);
  };

  const onClickAddNewTabButton = () => {
    setActiveTreatmentPlanTabIndex(treatmentTabs?.length);

    try {
      handleClickOnAddNewTabButton();
    } catch (error) {}
  };

  useEffect(() => {
    onCreateNewPlanTreatmentTab({
      color: activePlanTab?.color,
      treatments,
      onAddTreatment: addTreatmentsToPlan,
      index: activeTreatmentPlanTabIndex,
      onRemoveTreatment,
      onAddTreatmentListItem,
    });
  }, [treatmentTabs, activeTreatmentPlanTabIndex, onAddTreatmentListItem]);

  const handleTreatmentPlanDeleteButtonClick = (index) => {
    const isTreatments = Boolean(treatmentTabs[index]?.treatments?.length);

    if (!isTreatments) {
      onDeleteTreatmentPlanTab(index);
    } else {
      setHaveTreatments(isTreatments);
      setTreatmentTabIndexForDeletion(index);
    }
  };

  const handleCancelDeleteTreatmentPlan = () => {
    resetStates();
  };

  const handleDeleteTreatmentPlan = () => {
    onDeleteTreatmentPlanTab(treatmentTabIndexForDeletion);
    resetStates();
  };

  const resetStates = () => {
    setTreatmentTabIndexForDeletion(null);
    setHaveTreatments(false);
  };

  useEffect(() => {
    if (activeTreatmentPlanId) {
      const updatedPlanId = activeTreatmentPlanId;
      history?.push(`/patients/${patientId}/plans/${updatedPlanId}`);
    } else {
      history?.push(`/patients/${patientId}`);
    }
  }, [activeTreatmentPlanId]);

  useEffect(() => {
    if (activePlanIndex === -1) {
      setActiveTreatmentPlanTabIndex(0);
    } else {
      setActiveTreatmentPlanTabIndex(activePlanIndex);
    }
  }, [activePlanIndex]);

  return (
    <>
      <Snackbar {...notification} />
      <ConfirmationDialog
        haveTreatments={haveTreatments}
        onCancelDeleteTreatmentPlan={handleCancelDeleteTreatmentPlan}
        onDeleteTreatmentPlan={handleDeleteTreatmentPlan}
      />
      <TabWithText
        onChangeTab={handleTreatmentTabChange}
        selectedTabIndex={activeTreatmentPlanTabIndex}
        tabs={treatmentTabs}
        dataTestId="HES-TreatmentTabs"
        onClickAddNewTab={onClickAddNewTabButton}
        onDeleteButtonClick={handleTreatmentPlanDeleteButtonClick}
      />
    </>
  );
};
