import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Router, Switch } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import { RolloutFlagsService } from '../services/rolloutFlagsService';

const cssTokenLatestVersion = RolloutFlagsService.getInstance()
  .getFlags()
  .designTokenVersion?.getValue();
const cssTokenPath = `https://storage.googleapis.com/dechea-microservices-files/design-token-path/${cssTokenLatestVersion}/tokens/css/_variables.css`;

const Routes = ({ history }) => {
  useEffect(() => {
    fetch(cssTokenPath)
      .then((res) => res.text())
      .then((css) => {
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        head.appendChild(style);

        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
      });
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route history={history} component={Dashboard} exact path="/" />
        <Route
          history={history}
          component={Dashboard}
          exact
          path="/diagnosis"
        />
        <Route
          history={history}
          component={Dashboard}
          exact
          path="/patients/:patientId"
        />
        <Route
          history={history}
          component={Dashboard}
          exact
          path="/patients/:patientId/plans/:planId"
        />
      </Switch>
    </Router>
  );
};

Routes.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Routes;
