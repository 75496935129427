import { createMuiTheme } from '@material-ui/core/styles';
import { breakPointsValues } from './breakpoints';
import { h2 } from './typography/h2';
import { h4 } from './typography/h4';
import { h5 } from './typography/h5';
import { subtitle1 } from './typography/subtitle1';
import { subtitle2 } from './typography/subtitle2';
import { body1 } from './typography/body1';
import { body2 } from './typography/body2';
import { caption } from './typography/caption';
import { button } from './typography/button';
import MuiTextField from './components/MuiTextField';
import MuiInput from './components/MuiInput';
import MuiCheckbox from './components/MuiCheckbox';
import MuiButton from './components/MuiButton';
import MuiIcon from './components/MuiIcon';
import MuiAutocomplete from './components/MuiAutocomplete';
import MuiFormControl from './components/MuiFormControl';
import MuiGrid from './components/MuiGrid';
import MuiRadio from './components/MuiRadio';
import MuiFormLabel from './components/MuiFormLabel';
import MuiPopover from './components/MuiPopover';

import colors from './colors';

const {
  aliceBlue,
  weldonBlue,
  marinerBlue,
  cyanBlue,
  waikawaGray,
  paleNavyBlue,
  gulfBlue,
  rockBlue,
  catalinaBlue,
  royalBlue,
  spindleGray,
  solitudeGray,
  lightSolitudeGray,
  darkCatalinaBlue,
  shipCoveGray,
  midnightExpressBlue,
  sapphireBlue,
  redOrange,
  amaranthRed,
  whiteLilac,
  hawkesBlue,
  pantoneBlue,
  cabaretRed,
  lavender,
  tumbleweedBrown,
  watusiOrange,
  feldsparRed,
  selagoBlue,
  rhinoBlue,
  portGoreBlue,
  shipCoveBlue,
  poloBlue,
  zircon,
  glaucous,
} = colors;

export const muiTheme = createMuiTheme({
  breakpoints: {
    values: breakPointsValues,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: 'Montserrat,sans-serif !important',
      },
      h2,
      h4,
      h5,
      subtitle1,
      subtitle2,
      body1,
      body2,
      caption,
      button,
    },
    MuiGrid,
    MuiFormControl,
    MuiInput,
    MuiCheckbox,
    MuiTextField,
    MuiButton,
    MuiIcon,
    MuiAutocomplete,
    MuiRadio,
    MuiFormLabel,
    MuiPopover,
  },
  palette: {
    primary: {
      main: gulfBlue,
      light: cyanBlue,
    },
    weldonBlue,
    marinerBlue,
    aliceBlue,
    waikawaGray,
    paleNavyBlue,
    rockBlue,
    catalinaBlue,
    royalBlue,
    spindleGray,
    solitudeGray,
    lightSolitudeGray,
    darkCatalinaBlue,
    shipCoveGray,
    midnightExpressBlue,
    sapphireBlue,
    redOrange,
    amaranthRed,
    whiteLilac,
    hawkesBlue,
    pantoneBlue,
    cabaretRed,
    lavender,
    tumbleweedBrown,
    watusiOrange,
    feldsparRed,
    selagoBlue,
    rhinoBlue,
    portGoreBlue,
    shipCoveBlue,
    poloBlue,
    zircon,
    glaucous,
  },
});
