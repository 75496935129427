import React from 'react';
import ReactDOM from 'react-dom';
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import ReactGA from 'react-ga';

import { muiTheme } from './theme';
import { ContextProvider } from './store/provider';
import reportWebVitals from './reportWebVitals';
import NotificationMessage from './components/NotificationMessage';
import Routes from './router/Router';
import { history as browserHistory } from './router/history';
import { RolloutFlagsService } from './services/rolloutFlagsService';
import MuiSnackbar from './components/Mui/Snackbar';
import { FaunaDbService } from './services/faunadb/faunadb';
import { GraphqlProvider } from '@dechea/orc.graphql.provider';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import '@dechea/orc.services.library/tokens/css/_variables.css';

export let accessToken = '';
export let skeletonOptions = {};

const generateClassName = createGenerateClassName({
  productionPrefix: 'dechea-medical-discovery',
});

window.renderDiagnosis = (
  containerId,
  history,
  isAuthenticated,
  token,
  options
) => {
  accessToken = token?.accessToken;
  skeletonOptions = options;

  if (history && !isAuthenticated) {
    history.push('/login');
    return;
  }

  if (!history) {
    window.location.href = process.env.REACT_APP_SKELETON_URL
      ? `${process.env.REACT_APP_SKELETON_URL}patients`
      : 'http://localhost:8080/patients';
    return;
  }

  ReactGA.initialize('UA-177361705-1', {
    testMode: process.env.NODE_ENV === 'test',
  });

  getFaunaClient(accessToken).then((faunaInstance) => {
    if (faunaInstance?.client) {
      RolloutFlagsService.getInstance()
        .initRollout()
        .catch((error) => {})
        .finally(() => {
          ReactDOM.render(
            <GraphqlProvider accessToken={accessToken}>
              <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={muiTheme}>
                  <ContextProvider>
                    <Routes history={history || browserHistory} />
                    <NotificationMessage />
                    <MuiSnackbar />
                  </ContextProvider>
                </ThemeProvider>
              </StylesProvider>
            </GraphqlProvider>,
            document.getElementById(containerId)
          );
        });
    }
  });
  console.log('Medical discovery renderDiagnosis from microservice');
};

const getFaunaClient = (token) => {
  return new Promise((resolve) => {
    const faunaInstance = FaunaDbService.initFaunaDb(token);
    resolve(faunaInstance);
  });
};

window.unmountDiagnosis = (containerId) => {
  console.log('Medical discovery  unmountDiagnosis from microservice');
  const node = document.getElementById(containerId);
  ReactDOM.unmountComponentAtNode(node);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
