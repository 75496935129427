import { breakPoints } from '../breakpoints';

export const body1 = {
  fontStyle: 'normal',
  fontWeight: 500,
  [breakPoints.down('xl')]: {
    fontSize: 14,
  },
  [breakPoints.up('xl')]: {
    fontSize: 16,
  },
};
