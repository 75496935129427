/* eslint-disable tipsi/remove-event-listener */
import React, { useCallback, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { HealthStatus } from '@dechea/hes.apps.health-status';
import { TreatmentTabsWithText } from '../../components/TreatmentTabsWithText';
import { FaunaDbService } from '../../services/faunadb/faunadb';
import { accessToken } from '../..';

import styles from './dashboard.module.css';

import 'moment/locale/de';
import 'moment/locale/en-au';

const Dashboard = () => {
  const [isHealthStatusFocus, setIsHealthStatusFocus] = useState(true);
  const [activeTreatmentPlanTabData, setActiveTreatmentPlanTabData] =
    useState(0);
  const [patientUserId, setPatientUserId] = useState(null);

  const match = useRouteMatch();
  const patientId = match?.params?.patientId;
  const planId = match?.params?.planId;
  const language = new URLSearchParams(window.location.search).get('lng');

  language === 'de' ? moment.locale('de') : moment.locale('en');

  const { query, client } = FaunaDbService.getClientAndQuery();

  const getPatientUserData = async () => {
    const { Get, Match, Index, Ref, Collection } = query;

    const a = await client.query(
      Get(
        Match(
          Index('USR_GetUserByPatientRef'),
          Ref(Collection('USR_Patient'), patientId)
        )
      )
    );

    setPatientUserId(a?.ref?.value?.id);
  };

  useEffect(() => {
    getPatientUserData();
  }, []);

  // FOR CHECK FOCUS ON DISCOVERY CONTAINER
  const handleMouseDown = useCallback(
    (type) => {
      if (type === 'medicalDiscovery') {
        setIsHealthStatusFocus(true);
        return;
      }
      setIsHealthStatusFocus(false);
    },
    [setIsHealthStatusFocus]
  );

  useEffect(() => {
    const patient = document?.getElementById('skeletonPatientSidebar');
    const medicalDiscovery = document?.getElementById(
      'skeletonMedicalDiscoveryContainer'
    );

    patient?.addEventListener('mousedown', () => handleMouseDown('patient'));
    medicalDiscovery?.addEventListener('mousedown', () =>
      handleMouseDown('medicalDiscovery')
    );

    return () => {
      patient?.removeEventListener('mousedown', () =>
        handleMouseDown('patient')
      );
      medicalDiscovery?.removeEventListener('mousedown', () =>
        handleMouseDown('medicalDiscovery')
      );
    };
  }, [handleMouseDown]);

  const handleCreateNewTreatmentPlanTab = (treatmentData) => {
    setActiveTreatmentPlanTabData(treatmentData);
  };

  return (
    <Container
      className={styles.teethContainer}
      data-testid="teethContainer"
      maxWidth="lg"
    >
      <TreatmentTabsWithText
        onCreateNewPlanTreatmentTab={handleCreateNewTreatmentPlanTab}
        patientId={patientId}
        patientUserId={patientUserId}
        planId={planId}
      />
      <HealthStatus
        accessToken={accessToken}
        userId={patientId}
        isHealthStatusFocus={isHealthStatusFocus}
        activeTreatmentPlanTabData={activeTreatmentPlanTabData}
      />
    </Container>
  );
};

export default Dashboard;
