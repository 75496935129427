/* eslint-disable no-undef */
import FaunaDb from 'faunadb';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { FAUNADB } from '../../constants/config';

export const httpLink = createHttpLink({
  uri: FAUNADB.GRAPHQL_URL,
});

export class FaunaDbService {
  static client;
  static apolloClient;
  static query;

  static initFaunaDb(token) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    if (!this.client) {
      this.client = new FaunaDb.Client({
        secret: token,
        domain: FAUNADB.DOMAIN, //domain name vary according to region https://docs.fauna.com/fauna/current/api/fql/region_groups#how-to-use-region-groups
        scheme: 'https',
      });
      this.query = FaunaDb.query;

      this.apolloClient = new ApolloClient({
        uri: FAUNADB.GRAPHQL_URL,
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
      });
      return this;
    }
    return this;
  }

  static getClientAndQuery() {
    return this;
  }
}
