import colors from '../colors';

const MuiTextField = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    background: 'transparent',
    '& .Mui-focused': {
      color: `${colors.marinerBlue} !important`,
    },
    '& .MuiFormLabel-root': {
      color: colors.cyanBlue,
    },
    '& .Mui-error': {
      color: colors.amaranthRed,
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${colors.lavender}`,
      '&:hover': {
        borderBottom: `1px solid ${colors.lavender}`,
      },
    },
    '& ::after': {
      borderBottom: `1px solid ${colors.lavender}`,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: colors.amaranthRed,
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottomColor: `${colors.cyanBlue} !important`,
    },
  },
};

export default MuiTextField;
