/* eslint-disable no-undef */
import Rox from 'rox-browser';
import config from './config';

const flags = {
  designTokenVersion: new Rox.RoxString('1.0.1', []),
};

export class RolloutFlagsService {
  ROX_KEY = config.ROX_API_KEY;
  static instance;

  getFlags() {
    return flags;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RolloutFlagsService();
    }
    return this.instance;
  }

  async initRollout() {
    Rox.register('', flags);
    return Rox.setup(this.ROX_KEY);
  }
}
