import colors from '../colors';

const MuiPopover = {
  paper: {
    backgroundColor: colors.sapphireBlue,
    boxShadow: '0 25px 70px rgba(53,69,98,.15), 0 20px 50px rgba(53,69,98,.15)',
    backdropFilter: 'blur(144px)',
    borderRadius: '8px',
  },
};

export default MuiPopover;
