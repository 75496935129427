import { breakPoints } from '../breakpoints';

export const h2 = {
  fontStyle: 'normal',
  fontWeight: 700,
  [breakPoints.down('lg')]: {
    fontSize: 22,
  },
  [breakPoints.between('lg', 'xl')]: {
    fontSize: 32,
  },
  [breakPoints.up('xl')]: {
    fontSize: 41,
  },
};
