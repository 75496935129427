import colors from '../colors';

const MuiFormLabel = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: colors.cyanBlue,
    '& .Mui-error': {
      color: `${colors.redOrange} !important`,
    },
  },
};

export default MuiFormLabel;
