import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@dechea/orc.services.library';

import { dismissNotification as dismissNotificationAction } from '../../../store/actions/notification';
import connect from '../../../store/connect';

const SimpleSnackbar = (props) => {
  const { type, message, options, dismissNotification, open } = props;

  return (
    <Snackbar
      open={open}
      type={type}
      message={message}
      options={options}
      dismissNotification={dismissNotification}
    />
  );
};

const mapStateToProps = ({ notificationReducer }) => ({
  type: notificationReducer?.type,
  message: notificationReducer?.message,
  options: notificationReducer?.options,
  open: notificationReducer?.open,
});

const mapDispatchToProps = (dispatch) => ({
  dismissNotification: () => dispatch(dismissNotificationAction()),
});

const withConnect = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

SimpleSnackbar.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.object,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  dismissNotification: PropTypes.func,
};

export default withConnect(SimpleSnackbar);
