import { breakPoints } from '../breakpoints';

export const subtitle1 = {
  fontStyle: 'normal',
  fontWeight: 600,
  [breakPoints.down('lg')]: {
    fontSize: 14,
  },
  [breakPoints.up('lg')]: {
    fontSize: 16,
  },
};
