const colors = {
  aliceBlue: '#EFF5FC',
  weldonBlue: '#7E98BA',
  marinerBlue: '#346BD1',
  cyanBlue: '#7694BC',
  waikawaGray: '#556E9A',
  paleNavyBlue: '#ADBDD8',
  rockBlue: '#91a1bf',
  catalinaBlue: '#263857',
  darkCatalinaBlue: '#1F3764',
  royalBlue: '#4A7CDA',
  spindleGray: '#B6C0D1',
  solitudeGray: '#E8ECF2',
  lightSolitudeGray: '#E0E8F7',
  shipCoveGray: '#8494B1',
  midnightExpressBlue: '#0D1E3A',
  sapphireBlue: '#142A51',
  gulfBlue: '#314363',
  redOrange: '#f44336',
  amaranthRed: '#DC3545',
  whiteLilac: '#DEE5EE',
  hawkesBlue: '#D2DCE9',
  pantoneBlue: '#EBF0F6',
  cabaretRed: '#E04C66',
  lavender: '#E1E7F2',
  signalBlue: '#25406D',
  glaucous: '#6c86b5',
  radicalRed: '#FF1C5C',
  cardinalRed: '#C21044',
  carnationPink: '#FF98CC',
  paleVioletRed: '#DE5896',
  mayaBlue: '#68D6FF',
  bleuDeFrance: '#307CE7',
  aeroBlue: '#8eb2f4',
  bluetifulBlue: '#3a79ee',
  tumbleweedBrown: '#E1A68F',
  watusiOrange: '#F0C7B7',
  feldsparRed: '#CD8E74',
  white: '#000000',
  selagoBlue: '#F3F8FE',
  rhinoBlue: '#314363',
  portGoreBlue: '#3c4e6f',
  shipCoveBlue: '#8494b1',
  poloBlue: '#869DC5',
  zircon: '#FCFDFF',
};

export default colors;
