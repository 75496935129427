import React from 'react';
import styles from './notificationMessage.module.css';
import cx from 'classnames';
import { bin, tickmark } from '../../constants/icons';
import { useTranslation } from '@dechea/orc.services.library';
import connect from '../../store/connect';
import { dismissNotification as dismissNotificationAction } from '../../store/actions/notification';
import { notificationTypes } from '../../constants/notification';

const NotificationMessage = (props) => {
  const { type, message, options, dismissNotification, open } = props;
  const { t } = useTranslation();

  if (open) {
    return null;
  }

  if (!type && !message) {
    return null;
  }

  const onClickActionButton = () => {
    dismissNotification();
    if (options.actionButtonCallback) {
      options.actionButtonCallback();
    }
  };

  return (
    <div className={styles.container}>
      <span
        className={cx(
          {
            'bg-white text-primary':
              type === notificationTypes.SUCCESS || notificationTypes.FAILED,
            [styles.bgSecondary + ' text-white']:
              type === notificationTypes.NOTE_DELETED,
          },
          'flex-shrink-0',
          styles.notificationIcon
        )}
      >
        {/* Needs refactoring here. Just need some definite icon types success, error, warning, info, etc... */}
        {type === notificationTypes.SUCCESS && (
          <img
            className={styles.iconImg}
            src={tickmark}
            width={20}
            height={20}
            alt="note created"
          />
        )}
        {type === notificationTypes.FAILED && (
          <p className={styles.failedIconText}>X</p>
        )}
        {type === notificationTypes.NOTE_DELETED && (
          <img
            className={styles.iconImg}
            src={bin}
            width={20}
            height={20}
            alt="note deleted"
          />
        )}
      </span>
      <div className="flex-grow-1">
        <span
          className={cx(styles.notificationTypeText, 'mb-2 font-weight-bold')}
        >
          {/* Need to conditionally show status here */}
          {type === notificationTypes.SUCCESS && t('notification.success')}
          {type === notificationTypes.FAILED && t('notification.failed')}
        </span>
        <div className={styles.notificationMessage}>{t(message)}</div>
      </div>
      {options?.actionButtonText && (
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-white px-3 py-1 rounded-pill text-uppercase"
            onClick={onClickActionButton}
          >
            <small className="font-weight-bold text-primary">
              {options.actionButtonText}
            </small>
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ notificationReducer }) => ({
  type: notificationReducer?.type,
  message: notificationReducer?.message,
  options: notificationReducer?.options,
  open: notificationReducer?.open,
});

const mapDispatchToProps = (dispatch) => ({
  hideNotification: (patientId) =>
    dispatch(dismissNotificationAction(patientId)),
});

const withConnect = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withConnect(NotificationMessage);
