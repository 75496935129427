import { breakPoints } from '../breakpoints';

export const h4 = {
  fontStyle: 'normal',
  fontWeight: 700,
  [breakPoints.down('lg')]: {
    fontSize: 18,
  },
  [breakPoints.between('lg', 'xl')]: {
    fontSize: 22,
  },
  [breakPoints.up('xl')]: {
    fontSize: 26,
  },
};
