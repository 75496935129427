export const toothUpperViewIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/tooth_upper_view.svg';
export const deleteToothIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/deleteTooth.svg';
export const plusIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/add-icon.svg';
export const deleteIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/delete-icon.svg';
export const editIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/edit-icon.svg';
export const tickmark =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/tickmark.svg';
export const bin =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/bin.svg';
export const disableListIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/disable-list-icon.svg';
export const ellipse =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/ellipse.svg';
export const expandArrow =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/expandArrow.svg';
export const arrowBackIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/arrowBackIcon.svg';
export const noteIcon =
  'https://storage.googleapis.com/dechea-microservices-files/diagnostics/medical-discovery/noteIcon.svg';

export const successIcon =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/successIcon.svg';
export const failureIcon =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/failureIcon.svg';
