import colors from '../colors';

const MuiAutocomplete = {
  paper: {
    backgroundColor: colors.sapphireBlue,
  },
  listbox: {
    padding: 0,
  },
  option: {
    height: 48,
    color: 'white',
    lineHeight: '19.6px',
    borderBottom: `1px solid ${colors.signalBlue}`,
  },
  input: {
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '140%',
  },
  noOptions: {
    color: 'white',
  },
};

export default MuiAutocomplete;
