import React, { useEffect, useState } from 'react';
import { AlertDialog } from '@dechea/orc.ui.foundations.dialog';
import { useTranslation } from '@dechea/orc.services.library';

export const ConfirmationDialog = ({
  haveTreatments,
  onDeleteTreatmentPlan,
  onCancelDeleteTreatmentPlan,
}) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const closeDialog = () => {
    setOpen(false);
  };

  const toggleDialog = () => {
    if (haveTreatments) {
      setOpen(true);
    } else {
      closeDialog();
    }
  };

  useEffect(() => {
    toggleDialog();
  }, [haveTreatments]);

  const handleCloseButtonClick = () => {
    closeDialog();
    onCancelDeleteTreatmentPlan();
  };

  const handlePrimaryButtonClick = () => {
    closeDialog();
    onDeleteTreatmentPlan();
  };

  const handleSecondaryButtonClick = () => {
    closeDialog();
    onCancelDeleteTreatmentPlan();
  };

  return (
    <AlertDialog
      open={open}
      title={t('trpTreatment.deleteTreatmentPlanDialogHeading')}
      description={t('trpTreatment.deleteTreatmentPlanDialogDescription')}
      onCloseButtonClick={handleCloseButtonClick}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      onSecondaryButtonClick={handleSecondaryButtonClick}
      primaryButtonLabel={t('common.delete')}
      secondaryButtonLabel={t('common.cancel')}
    />
  );
};
