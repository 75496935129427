const MuiIcon = {
  root: {
    width: 24,
    height: 24,
    fontSize: 'unset',
    overflow: 'unset',
  },
};

export default MuiIcon;
